export const canUploadDocIds = {
  electroCarsUserIds: [
    12372298, 12986106, 10742944, 13148979, 12475771, 12456593, 12907308, 13167128, 11854668,
    12093601, 12090399, 5099270, 13204562, 11554537, 12408321, 13007269, 10826059, 13564013,
    9763509, 375470, 7470210, 5380927, 11513948, 11302935, 9763509, 7341619, 8336392, 344807,
    361774, 12972223, 9717714, 7487409, 4961996, 12964367, 949930, 11687367, 13582827, 10467106,
    10886254, 14213414, 2049564, 14129552, 13205286, 13376442, 7935212, 13399813, 7099561,
    12365384, 5814186, 11815325, 9612976, 7350048, 11262657, 12560226, 8562371, 14224478,
    1202646, 14597261, 14586412, 9281107, 20173, 1485094, 14161034, 13772094, 3942286, 10038301,
    13642699, 14070127, 13447605, 12773439, 12165393, 13397798, 13932305, 13474673, 13586162,
    13801000, 14842311, 14747898, 14075143
  ],
  hybridCarsUserIds: [
    12475771, 14224478, 12907308, 12456593, 10742944, 13148979, 14597261, 14586412, 13642699,
    14070127, 11687367, 13447605, 12773439, 12165393,
  ],
};
